<template>
    <div>
        <h3>{{ $t('ordonnance.renommer_fichiers') }}</h3>

        <div class="form-group input-group-append" v-for="document in documents" :key="document.document_id">
			<input type="text" class="form-control" v-model="document.document_name" :placeholder="$t('ordonnance.donner_un_nom')" required>
			<e-datepicker v-model="document.document_date" required />
        </div>

        <ButtonValidation @btn-onclick="valid_form_multiple" icon="suivant" />
    </div>
</template>

<script type="text/javascript">
    import Shutter from '@/mixins/Shutter.js'
    import Documents from "@/mixins/Documents.js"

	export default {
		name: "OrdonnanceRenameForm",
		mixins: [Documents, Shutter],
        props: [
            'documents',
			'horses_ids',
			'from_mediatheque',
			'acte_ids'
        ],
        components: {
            ButtonValidation: () => import('GroomyRoot/components/Buttons/Validation'),
            ErrorFileSize: () => import('GroomyRoot/components/Alert/ErrorAlert')
        },
		mounted() {
			this.init_component()
		},
		methods: {
			init_component() {
				if(this.documents.length < 2) {
					this.registerHorseDocument(this.documents)
				}
			},

            async valid_form_multiple() {
				return this.registerHorseDocument(this.documents)
            },

			async registerHorseDocument(documents) {
				let tab_promises = []

				documents.forEach(doc => {
					tab_promises.push(new Promise(async (res, _) => {
						const document_name = doc.document_name
						const document_date = doc.document_date
						const document_file = doc.document_file

						await this.addHorseDocument(this.horses_ids, document_name, document_date, 1, document_file, this.acte_ids)
						return res()
					}))
				})

				Promise.all(tab_promises)
				.then(() => {
					this.successToast('toast.info_save_succes')
					if(this.from_mediatheque == true){
						this.ok()
					}
					this.$emit("success")
				})
				.catch(() => {
					this.failureToast('toast.info_save_failed')
				})
			}
		}
	}
</script>
